import { useState } from "react";

import "./App.css";

const App = () => {
  const ENUM_SERVER = {
    DEV: "DEV",
    STAGING: "STAGING",
    PROD: "PROD",
  };
  const [unitId, setUnitId] = useState("");
  const [config, setConfig] = useState("");
  const [server, setServer] = useState(ENUM_SERVER.STAGING);

  const handleUnitIdChange = (event) => {
    setUnitId(event.target.value);
  };

  const handleConfigChange = (event) => {
    setConfig(event.target.value);
  };

  const handleServerChange = (event) => {
    setServer(event.target.value);
  };

  const getServerParameter = (server) => {
    if (server === ENUM_SERVER.DEV) {
      return "buzzscreen:dev";
    } else if (server === ENUM_SERVER.STAGING) {
      return "buzzscreen:staging";
    }
    return "buzzscreen";
  };

  const buildCommand = (server, unitId, command) => {
    try {
      const encodedCommand = encodeURIComponent(
        JSON.stringify(JSON.parse(command))
      );
      return `@kiki ${getServerParameter(
        server
      )} set_feed_config --unit_id ${unitId} --config_data ${encodedCommand}`;
    } catch (e) {
      return "Invalid JSON";
    }
  };

  const command = buildCommand(server, unitId, config);

  return (
    <div className="App">
      <header className="App-header">Custom Tabs Command Generator</header>
      <main className="App-main">
        <ul>
          <li>
            <h3>1. Write a config JSON</h3>
            <div>
              ex){" "}
              <a href="https://vscode.dev/" target="_blank" rel="noreferrer">
                VS Code Online
              </a>
              <ol>
                <li>Click above link to open VS Code online</li>
                <li>Click 'New File...'</li>
                <li>Click 'Select a language'</li>
                <li>Choose 'JSON' or 'JSON with Comments'</li>
              </ol>
            </div>
          </li>
          <li>
            <h3>2. Input the Unit ID and paste the config JSON here</h3>
            <div>
              <div>
                <div>
                  <input
                    type="radio"
                    value={ENUM_SERVER.DEV}
                    name="server"
                    id="radio-server-dev"
                    onChange={handleServerChange}
                    checked={server === ENUM_SERVER.DEV}
                  />{" "}
                  <label htmlFor="radio-server-dev">Dev</label>
                  <input
                    type="radio"
                    value={ENUM_SERVER.STAGING}
                    name="server"
                    id="radio-server-staging"
                    onChange={handleServerChange}
                    checked={server === ENUM_SERVER.STAGING}
                  />{" "}
                  <label htmlFor="radio-server-staging">Staging</label>
                  <input
                    type="radio"
                    value={ENUM_SERVER.PROD}
                    name="server"
                    id="radio-server-prod"
                    onChange={handleServerChange}
                    checked={server === ENUM_SERVER.PROD}
                  />{" "}
                  <label htmlFor="radio-server-prod">Prod</label>
                </div>
                <label htmlFor="unit-id">
                  Unit ID:{" "}
                  <input
                    type="text"
                    placeholder="Unit ID"
                    value={unitId}
                    onChange={handleUnitIdChange}
                  ></input>
                </label>
              </div>
              <div>
                <label htmlFor="config-json">Config JSON</label>
                <textarea
                  rows="5"
                  placeholder="Paste the config JSON here"
                  value={config}
                  onChange={handleConfigChange}
                ></textarea>
              </div>
            </div>
          </li>
          <li>
            <h3>
              3. Copy the generated command and paste into{" "}
              <a href="https://buzzvil.slack.com/archives/C01STF6H18A">
                #supply-command
              </a>
              .
            </h3>
            <textarea
              rows="5"
              placeholder="The command will be generated automatically"
              value={command}
              disabled
            ></textarea>
          </li>
        </ul>
      </main>
      <footer className="App-footer">
        If you have any questions or comments,
        <br /> please feel free to contact Brice.
      </footer>
    </div>
  );
};

export default App;
